export default Object.freeze({
  CONSULTORIA_ATIVA_RS: {
    name: 'Consultoria ativa RS',
    description: 'Consultoria para processos do Gercon',
    value: 'consultoria-ativa-rs',
  },
  // REGULACAO_ATIVA: {
  //   name: 'Regulação Ativa',
  //   description: 'Descrição resumida desse serviço',
  //   value: 'regulacao-ativa',
  // },
});

import type { RouteLocation } from 'vue-router';

import TelessaudeModsEnum from '@/utils/Enums/TelessaudeModsEnum';

import HealthcareAssistanceProfilesEnum from '@md/HealthcareAssistance/utils/Enums/HealthcareAssistanceProfilesEnum';
import DynamicPrescriptionServices from '@md/HealthcareAssistance/utils/Enums/DynamicPrescriptionServices';

const serviceAcceptValues = Object.values(DynamicPrescriptionServices)
  .map((service) => service.value)
  .join('|');

export default [
  {
    path: '/consultorias',
    component: () => import(/* webpackChunkName: "healthcare-assistance-module" */ '@md/HealthcareAssistance/HealthcareAssistanceModule.vue'),
    meta: {
      permissions: {
        mods: [TelessaudeModsEnum.LEGACY],
        services: {
          '0800': [ HealthcareAssistanceProfilesEnum.REGULADOR, ],
        }
      },
    },
    children: [
      {
        path: 'regulacao-ativa',
        redirect: { name: 'healthcare-assistance.dynamic-prescription', },
      },
      {
        path: 'regulacao/:service/:ticket_id',
        redirect: (to: RouteLocation) => {
          let { service } = to.params;
          if (service === 'regulacao-ativa-rs') service = 'consultoria-ativa-rs';
          return {
            name: 'healthcare-assistance.dynamic-prescription-form',
            params: { ...to.params, service },
          };
        },
      },
      {
        path: 'consultoria-ativa',
        name: 'healthcare-assistance.dynamic-prescription',
        component: () => import(/* webpackChunkName: "healthcare-assistance.dynamic-prescription" */ '@md/HealthcareAssistance/views/DynamicPrescriptionManagement.vue'),
      },
      {
        path: `consultoria/:service(${serviceAcceptValues})/:ticket_id`,
        name: 'healthcare-assistance.dynamic-prescription-form',
        component: () => import(/* webpackChunkName: "healthcare-assistance.dynamic-prescription-form" */ '@md/HealthcareAssistance/views/DynamicPrescriptionForm.vue'),
      },
    ],
  },
];

export default [
  {
    path: 'hipoteses',
    component: () => import(/* webpackChunkName: "hypothesis" */ '@/modules/Hypothesis/HypothesisModule.vue'),
    children: [
      {
        path: '',
        name: 'hypothesis.list',
        component: () => import(/* webpackChunkName: "hypothesis.list" */ '@/modules/Hypothesis/views/HypothesisManagement.vue'),
      },
      {
        path: 'novo',
        name: 'hypothesis.add',
        component: () => import(/* webpackChunkName: "hypothesis.add" */ '@/modules/Hypothesis/views/HypothesisManagementForm.vue'),
      },
      {
        path: 'editar/:uuid',
        name: 'hypothesis.edit',
        component: () => import(/* webpackChunkName: "hypothesis.edit" */ '@/modules/Hypothesis/views/HypothesisManagementForm.vue'),
      },
    ],
  },
];

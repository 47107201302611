import TelessaudeModsEnum from '@/utils/Enums/TelessaudeModsEnum'

export default [
  {
    path: 'bibliografias',
    component: () => import(/* webpackChunkName: "bibliography" */ '@/modules/Bibliography/BibliographyModule.vue'),
    meta: {
      permissions: { mods: [TelessaudeModsEnum.LEGACY], },
    },
    children: [
      {
        path: '',
        name: 'bibliography.list',
        component: () => import(/* webpackChunkName: "bibliography.list" */ '@/modules/Bibliography/views/BibliographyManagement.vue'),
      },
      {
        path: 'novo',
        name: 'bibliography.add',
        component: () => import(/* webpackChunkName: "bibliography.add" */ '@/modules/Bibliography/views/BibliographyManagementForm.vue'),
      },
      {
        path: 'editar/:uuid',
        name: 'bibliography.edit',
        component: () => import(/* webpackChunkName: "bibliography.edit" */ '@/modules/Bibliography/views/BibliographyManagementForm.vue'),
      },
    ],
  },
];
